<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-31 11:40:11
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-18 14:03:26
 * @FilePath: /mediatom-web/src/components/sourceModal/CustomStrategyForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="custom-strategu-form-container">
    <a-divider>媒体出价和CPA定制</a-divider>
    <a-form-model-item label="定制状态" prop="mediaAndDspPriceSwitch">
      <div class="switch-box">
        <a-radio-group name="radioGroup" v-model="formquery.mediaAndDspPriceSwitch">
          <a-radio :value="1"> 开启 </a-radio>
          <a-radio :value="0"> 关闭 </a-radio>
        </a-radio-group>
        <a-button type="link" v-if="formquery.mediaAndDspPriceSwitch === 1" @click="handleAddStrategyItem">新增策略</a-button>
        <a-button type="link" v-else></a-button>
      </div>
    </a-form-model-item>
    <template v-if="formquery.mediaAndDspPriceSwitch === 1">
      <CustomStrategyFormItem
        v-for="(item, index) in formquery.mediaAndDspPriceTacticVOS"
        :key="index"
        arrProp="mediaAndDspPriceTacticVOS"
        :index="index"
        :item="item"
        :query="query"
        @deleteItem="deleteItem(index)"
        v-on="$listeners"></CustomStrategyFormItem>
    </template>
  </div>
</template>

<script>
import CustomStrategyFormItem from './CustomStrategyFormItem'
export default {
  name: 'CustomStrategyForm',
  components: { CustomStrategyFormItem },
  data () {
    return {
      formqueryTemp: {
        mediaAndDspPriceSwitch: 1,
        mediaAndDspPriceTacticVOS: []
      }
    }
  },
  created () {
    this.$set(this.formquery, 'mediaAndDspPriceSwitch', this.query.mediaAndDspPriceSwitch || 0)
    this.$set(this.formquery, 'mediaAndDspPriceTacticVOS', this.query.mediaAndDspPriceTacticVOS || [])
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('update:query', formquery)
      }
    }
  },
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleAddStrategyItem () {
      if (!this.formquery.mediaAndDspPriceTacticVOS) {
        this.$set(this.formquery, 'mediaAndDspPriceTacticVOS', [])
      }
      this.formquery.mediaAndDspPriceTacticVOS.push({})
    },
    deleteItem (index) {
      this.formquery.mediaAndDspPriceTacticVOS.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.switch-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
