<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-31 14:20:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:37:02
 * @FilePath: /mediatom-web/src/components/sourceModal/CustomStrategyForm/CustomStrategyFormItem/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="score-item-container">
    <a-form-model-item :label="indexTitle" v-if="index !== 0">
      <div class="clear-box">
        <a-icon type="close" class="icon" @click="$emit('deleteItem')"/>
      </div>
    </a-form-model-item>
    <a-form-model-item label="质量分" :prop="`${arrProp}.score`" :rules="[{ required: true, message: '请输入质量分', trigger: 'blur' }]">
      <a-input class="score-input" :style="{width: `calc(100% - ${index === 0 ? '100' : 0}px)`}" type="number" v-model.number.trim="item.score" placeholder="请输入质量分"></a-input>
      <a-button class="score-btn" v-if="index === 0" type="link" @click="$emit('handleAddScore')">新增质量分</a-button>
    </a-form-model-item>
    <MediaAndTopStrategyForm :query="item" :propText="`${arrProp}.`" v-on="$listeners"></MediaAndTopStrategyForm>
  </div>
</template>

<script>
import MediaAndTopStrategyForm from '../../../MediaAndTopStrategyForm'
export default {
  name: 'CustomStrategyForm',
  components: { MediaAndTopStrategyForm },
  data () {
    return {
    }
  },
  created () {
    this.$set(this.item, 'score', this.item.score || undefined)
  },
  computed: {
    indexTitle () {
      return `质量分${this.index}`
    }
  },
  props: {
    item: {
      default: () => ({}),
      type: Object
    },
    // 完整表单
    query: {
      type: Object,
      default: () => ({})
    },
    // 索引
    index: {
      default: 0,
      type: Number
    },
    // 数组key
    arrProp: {
      default: '',
      type: String
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.score-item-container{
  .clear-box{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon{
      cursor: pointer;
    }
  }
}
</style>
