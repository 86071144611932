<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-31 14:20:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:36:49
 * @FilePath: /mediatom-web/src/components/sourceModal/CustomStrategyForm/CustomStrategyFormItem/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="custom-strategu-form-item-container">
    <a-form-model-item :label="indexTitle">
      <div class="clear-box">
        <a-icon type="close" class="icon" @click="$emit('deleteItem')"/>
      </div>
    </a-form-model-item>
    <a-form-model-item label="任务类型" :prop="`${arrProp}[${index}].conditionType`" :rules="[{ required: true, message: '请选择任务类型', trigger: 'change' }]">
      <a-select v-model="item.conditionType" placeholder="请选择任务类型" allowClear @change="changeConditionType">
        <a-select-option v-for="child in conditionTypeList" :key="child.id" :value="child.id">{{ child.name }}</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item v-if="item.conditionType === 1 || item.conditionType === 3" label="任务ID" :prop="`${arrProp}[${index}].dspTask`" :rules="[{ required: true, message: '请输入任务ID', trigger: 'blur' }]">
      <a-input v-model.trim="item.dspTask" placeholder="请输入任务ID"></a-input>
    </a-form-model-item>
    <a-form-model-item v-if="item.conditionType === 2" label="质量分" :prop="`${arrProp}[${index}].score`" :rules="[{ required: true, message: '请输入质量分', trigger: 'blur' }]">
      <a-input class="score-input" type="number" v-model.number.trim="item.score" placeholder="请输入质量分"></a-input>
    </a-form-model-item>
    <template v-if="item.conditionType === 3">
      <ScoreItem
        v-for="(score, i) in item.mediaAndDspPriceTacticSubVOS"
        :key="i"
        :item="score"
        :index="i"
        :query="query"
        @deleteItem="deleteItem(i)"
        @handleAddScore="handleAddScore"
        v-on="$listeners"
        :arrProp="`${arrProp}[${index}].mediaAndDspPriceTacticSubVOS[${i}]`"></ScoreItem>
    </template>
    <!-- 传入该作用域所在的位置 ${arrProp}[${index}]. -->
    <MediaAndTopStrategyForm :query="item" :propText="`${arrProp}[${index}].`" v-on="$listeners" :isDefault="item.conditionType === 3"></MediaAndTopStrategyForm>
  </div>
</template>

<script>
import MediaAndTopStrategyForm from '../../MediaAndTopStrategyForm'
import ScoreItem from './ScoreItem'
export default {
  name: 'CustomStrategyForm',
  components: { MediaAndTopStrategyForm, ScoreItem },
  data () {
    return {
      conditionTypeList: [
        {
          name: '任务',
          id: 1
        },
        {
          name: '质量分',
          id: 2
        },
        {
          name: '任务 + 质量分',
          id: 3
        }
      ]
    }
  },
  created () {
    this.$set(this.item, 'conditionType', this.item.conditionType || undefined)
    this.$set(this.item, 'dspTask', this.item.dspTask || undefined)
    this.$set(this.item, 'score', this.item.score || undefined)
    this.$set(this.item, 'mediaAndDspPriceTacticSubVOS', this.item.mediaAndDspPriceTacticSubVOS || [
      {
        score: undefined
      }
    ])
  },
  computed: {
    indexTitle () {
      return `定制策略${this.index + 1}`
    }
  },
  props: {
    item: {
      default: () => ({}),
      type: Object
    },
    // 索引
    index: {
      default: 0,
      type: Number
    },
    // 数组key
    arrProp: {
      default: '',
      type: String
    },
    query: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
    handleAddScore () {
      this.item.mediaAndDspPriceTacticSubVOS.push({})
    },
    deleteItem (i) {
      this.item.mediaAndDspPriceTacticSubVOS.splice(i, 1)
    },
    changeConditionType (e) {
      if (e === 3) {
        this.$set(this.item, 'mediaAndDspPriceTacticSubVOS', [{}])
      }
    }
  }
}
</script>

<style lang="less" scoped>
.custom-strategu-form-item-container{
  .clear-box{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .icon{
      cursor: pointer;
    }
  }
}
</style>
